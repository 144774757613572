import React, { useState, useEffect } from "react";
import Logo from "../images/logo.png";
import { Link, useNavigate } from "react-router-dom";

const Navbar = () => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    const token = localStorage.getItem("token");
    const user = JSON.parse(localStorage.getItem("userData"));

    if (token && user) {
      setIsLoggedIn(true);
      setUserData(user); // ✅ user data set krwa diya
    }
  }, []);

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("userData");
    setIsLoggedIn(false);
    setUserData(null);
    navigate("/login");
  };

  const handleScrollToSection = (section) => {
    navigate("/");
    setTimeout(() => {
      const element = document.getElementById(section);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }, 100);
  };

  return (
    <nav className="py-1 px-8 md:px-16 bg-white max-w-[1200px] m-auto flex items-center justify-between rounded-2xl">
      <div>
        <Link to={"/"} className="text-lg font-bold">
          <img src={Logo} alt="" className="h-8" />
        </Link>
      </div>
      <div className="group">
        <button className="bg-white w-10 h-10 rounded text-lg block md:hidden hover:bg-gradient-to-tr from-blue-500 to-purple-500 hover:text-white">
          <i className="ri-menu-3-line"></i>
        </button>
        <ul className="md:flex hidden group-hover:flex md:items-center items-start gap-5 text-xs font-medium md:relative absolute bg-white md:bg-inherit flex-col md:flex-row top-14 md:top-1 right-5 md:right-0 rounded-md md:rounded-none drop-shadow-md md:drop-shadow-none p-4 md:p-0 w-[60%] md:w-max">
          <li>
            <Link to={"/"} className=" hover:underline">
              Home
            </Link>
          </li>
          <li>
            <Link to={"/tools"} className="hover:underline">
              Tools
            </Link>
          </li>
          <li>
            <button
              onClick={() => handleScrollToSection("Features")}
              className="hover:underline"
            >
              Features
            </button>
          </li>
          <li>
            <Link to={"/pricing"} className="hover:underline">
              Pricing
            </Link>
          </li>
          <li>
            <Link to={"/blog"} className=" hover:underline">
              News & Blogs
            </Link>
          </li>
          <li>
            <Link to={"/chatBot"} className=" hover:underline">
            AI Grammar Assistant 
            </Link>
          </li>
          <button
            onClick={() => handleScrollToSection("about")}
            className="hover:underline"
          >
            About Us
          </button>

          <li className="flex items-center gap-1.5">
            <Link
              to={"/contact"}
              className="border border-gray-600 py-1.5 px-2 rounded-md hover:border-blue-500 hover:bg-blue-500 hover:bg-opacity-20"
            >
              Support<i className="ri-arrow-right-line"></i>
            </Link>
          </li>

          {isLoggedIn ? (
            <li className="flex items-center gap-1.5">
              <div className="relative">
                <button
                  onClick={() => setIsOpen(!isOpen)}
                  className="flex items-center gap-2 border px-3 py-1 rounded-md hover:border-purple-500"
                >
                  {/* ✅ Profile Picture Avatar */}
                  <img
                    src={`https://api.dicebear.com/7.x/initials/svg?seed=${userData?.firstName} ${userData?.lastName}`}
                    alt="Profile"
                    className="w-8 h-8 rounded-full"
                  />
                  {/* ✅ FirstName + LastName */}
                  {userData?.firstName} {userData?.lastName}
                </button>

                {isOpen && (
                  <div className="absolute right-0 mt-2 w-40 bg-white shadow-md rounded-md z-50">
                    <Link
                      to="/profile"
                      className="block px-4 py-2 text-gray-800 hover:bg-gray-100"
                      onClick={() => setIsOpen(false)}
                    >
                      Profile
                    </Link>
                    <button
                      onClick={handleLogout}
                      className="block w-full text-left px-4 py-2 text-red-500 hover:bg-gray-100"
                    >
                      Logout
                    </button>
                  </div>
                )}
              </div>
            </li>
          ) : (
            <li>
              <Link
                to="/login"
                className="border border-gray-600 py-1.5 px-2 rounded-md hover:border-blue-500 hover:bg-blue-500 hover:bg-opacity-20"
              >
                Login
              </Link>
            </li>
          )}
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;
