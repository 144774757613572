import { Link } from "react-router-dom";
import config from './config.json';

function Tutorials({ tutorials }) {
  return (
    <div className="bg-slate-100 text-black">
      {/* ✅ Banner Section */}
      <div className="bg-gradient-to-tl to-[#a855f730] from-[#3b82f630] -mt-12">
        <div className="max-w-[920px] m-auto px-4 md:px-0 py-28">
          <h1 className="text-5xl font-bold gradient-text text-center">
            Explore Tutorials
          </h1>
          <p className="mt-5 w-[80%] m-auto text-center text-gray-600">
            Explore our latest tutorials to level up your coding skills!
          </p>
        </div>
      </div>

      {/* ✅ Tutorials Cards Section */}
      <main className="max-w-[1100px] m-auto px-4 md:px-0 flex-grow">
        <div className="bg-white p-6 rounded-xl shadow-lg">
          <div className="grid md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-6">
            {tutorials.map((tutorial) => (
              <div
                key={tutorial.id}
                className="bg-slate-50 rounded-xl shadow-md overflow-hidden transition-transform transform hover:-translate-y-1 hover:shadow-2xl"
              >
                {/* ✅ Image Section */}
                <img
                 src={`${config.apiUrl}/${tutorial.featureImage}`}
                  alt={tutorial.title}
                  className="w-full h-48 object-cover"
                />

                {/* ✅ Content Section */}
                <div className="p-5">
                  <p className="text-xs text-gray-400">
                    {new Date(tutorial.date).toLocaleDateString()}
                  </p>
                  <h3 className="font-semibold text-lg text-gray-800 mt-2">
                    {tutorial.title}
                  </h3>
                  <p className="text-sm text-gray-600 mt-1 line-clamp-2">
                    {tutorial.description}
                  </p>

                  {/* ✅ Gradient Button */}
                  <Link
                    to={`/details/${tutorial._id}`}
                    className="mt-4 inline-block px-4 py-2 rounded-lg bg-gradient-to-r from-purple-500 to-blue-500 text-white font-medium hover:from-blue-500 hover:to-purple-500 transition duration-300"
                  >
                    Read More →
                  </Link>
                </div>
              </div>
            ))}
          </div>
        </div>
      </main>
    </div>
  );
}

export default Tutorials;
