import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Navbar from "./components/Navbar";
import axios from "axios";
import config from "./config.json";

const Profile = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [user, setUser] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    subscription: null,
  });
  const [activeTab, setActiveTab] = useState("profile");
  const [isEditing, setIsEditing] = useState(false);
  const [loading, setLoading] = useState(false);
  const token = localStorage.getItem("token");

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const res = await axios.get(`${config.apiUrl}/auth/profile`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setUser(res.data);
      } catch (error) {
        console.error("Error fetching profile:", error);
        navigate("/login");
      }
    };

    // const handleStripeSession = async (sessionId) => {
    //   try {
    //     await axios.get(`${config.apiUrl}/payment/stripe/session/${sessionId}`);
    //     const res = await axios.get(`${config.apiUrl}/auth/profile`, {
    //       headers: {
    //         Authorization: `Bearer ${token}`,
    //       },
    //     });
    //     setUser(res.data);
    //   } catch (error) {
    //     console.error("Error handling Stripe session:", error);
    //   } finally {
    //     // ✅ Session complete hone ke baad chahe success ho ya error — redirect to profile
    //     navigate("/profile");
    //   }
    // };

    if (token) {
      fetchProfile();
      const searchParams = new URLSearchParams(location.search);
      // const sessionId = searchParams.get("session_id");
      // if (sessionId) {
      //   handleStripeSession(sessionId);
      // }
    } else {
      navigate("/login");
    }
  }, [token, navigate, location]);

  const handleSaveProfile = async () => {
    setLoading(true);
    try {
      const res = await axios.put(`${config.apiUrl}/auth/profile`, user, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      if (res.data.emailChanged) {
        const updatedUserData = {
          ...JSON.parse(localStorage.getItem("userData")),
          email: user.email,
        };
        localStorage.setItem("userData", JSON.stringify(updatedUserData));
        navigate("/verification");
      } else {
        setIsEditing(false);
      }
    } catch (error) {
      console.error("Error updating profile:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleCancelSubscription = async (immediateCancel = false) => {
    try {
      if (!user?.stripeSubscriptionId) {
        alert("No active subscription found");
        return;
      }

      const response = await axios.post(
        `${config.apiUrl}/payment/cancel-subscription`,
        {
          subscriptionId: user.stripeSubscriptionId,
          immediateCancel,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      console.log("✅ Subscription cancellation request sent:", response.data);

      if (immediateCancel) {
        // ✅ Immediately cancel ke case me state null karo
        setUser((prev) => ({
          ...prev,
          subscription: null,
          isSubscribed: false,
          subscriptionStatus: "canceled",
        }));
        alert("Subscription cancelled immediately!");
      } else {
        // ✅ Cancel at period end ke case me status ko webhook ke through update hone do
        setUser((prev) => ({
          ...prev,
          subscription: {
            ...prev.subscription,
            cancel_at_period_end: true, // ✅ Cancel at period end flag
          },
        }));
        alert("Subscription will be cancelled at the end of the period!");
      }

      // ✅ Redirect to pricing page after cancellation
      navigate("/pricing");
    } catch (error) {
      console.error("❌ Error cancelling subscription:", error);
      alert(error.response?.data?.message || "Failed to cancel subscription");
    }
  };

  const tabs = ["profile", user.subscription && "subscription"].filter(Boolean);

  return (
    <div className="bg-slate-100 text-black min-h-screen">
      <div className="bg-gradient-to-tl to-[#a855f730] from-[#3b82f630] py-20 text-center">
        <h1 className="text-4xl font-bold text-gray-800">Profile Settings</h1>
        <p className="mt-2 text-gray-600">
          Manage your profile information and subscription details
        </p>
      </div>

      <div className="max-w-5xl mx-auto mt-10 bg-white shadow-xl rounded-xl overflow-hidden">
        <div className="flex">
          <div className="w-1/4 bg-gray-50 p-6 border-r">
            <h2 className="text-xl font-semibold mb-4">Settings</h2>
            <ul className="space-y-2">
              {tabs.map((tab) => (
                <li key={tab}>
                  <button
                    onClick={() => setActiveTab(tab)}
                    className={`w-full text-left px-4 py-2 rounded-lg font-medium ${
                      activeTab === tab
                        ? "bg-purple-500 text-white"
                        : "hover:bg-gray-100 text-gray-700"
                    }`}
                  >
                    {tab.charAt(0).toUpperCase() + tab.slice(1)}
                  </button>
                </li>
              ))}
            </ul>
          </div>

          <div className="w-3/4 p-8">
            {activeTab === "profile" && (
              <div>
                <div className="flex justify-between items-center mb-6">
                  <h2 className="text-2xl font-bold text-gray-800">
                    Profile Info
                  </h2>
                  {isEditing ? (
                    <button
                      onClick={handleSaveProfile}
                      className={`bg-purple-500 text-white px-4 py-2 rounded-lg transition ${
                        loading
                          ? "opacity-50 cursor-not-allowed"
                          : "hover:bg-purple-600"
                      }`}
                      disabled={loading}
                    >
                      {loading ? "Saving..." : "Save"}
                    </button>
                  ) : (
                    <button
                      onClick={() => setIsEditing(true)}
                      className="bg-purple-500 text-white px-4 py-2 rounded-lg hover:bg-purple-600 transition"
                    >
                      Edit
                    </button>
                  )}
                </div>

                <div className="grid grid-cols-2 gap-6">
                  <div>
                    <label className="block text-gray-600 mb-1">
                      First Name
                    </label>
                    <input
                      type="text"
                      value={user.firstName}
                      onChange={(e) =>
                        setUser({ ...user, firstName: e.target.value })
                      }
                      className={`w-full border px-3 py-2 rounded-lg ${
                        isEditing ? "bg-white" : "bg-gray-100"
                      }`}
                      disabled={!isEditing}
                    />
                  </div>
                  <div>
                    <label className="block text-gray-600 mb-1">
                      Last Name
                    </label>
                    <input
                      type="text"
                      value={user.lastName}
                      onChange={(e) =>
                        setUser({ ...user, lastName: e.target.value })
                      }
                      className={`w-full border px-3 py-2 rounded-lg ${
                        isEditing ? "bg-white" : "bg-gray-100"
                      }`}
                      disabled={!isEditing}
                    />
                  </div>
                  <div className="col-span-2">
                    <label className="block text-gray-600 mb-1">Email</label>
                    <input
                      type="email"
                      value={user.email}
                      onChange={(e) =>
                        setUser({ ...user, email: e.target.value })
                      }
                      className={`w-full border px-3 py-2 rounded-lg ${
                        isEditing ? "bg-white" : "bg-gray-100"
                      }`}
                      disabled={!isEditing}
                    />
                  </div>
                </div>
              </div>
            )}
            {activeTab === "subscription" && (
              <div>
                <h2 className="text-2xl font-bold text-gray-800 mb-4">
                  Subscription Info
                </h2>

                {/* ✅ No active subscription case */}
                {!user?.subscription?.planName ? (
                  <>
                    <p>No active subscription</p>
                    <button
                      onClick={() => navigate("/pricing")}
                      className="mt-4 bg-purple-500 text-white px-4 py-2 rounded-lg hover:bg-purple-600"
                    >
                      Choose Plan
                    </button>
                  </>
                ) : (
                  <>
                    <p>
                      <strong>Plan:</strong>{" "}
                      {user.subscription.planName || "N/A"}
                    </p>
                    <p>
                      <strong>Price:</strong>{" "}
                      {user.subscription.price
                        ? `$${user.subscription.price}/${
                            user.subscription.interval || "month"
                          }`
                        : "N/A"}
                    </p>
                    <p>
                      <strong>Status:</strong>{" "}
                      {user.subscription.status || "N/A"}
                    </p>
                    {user.subscription.trial_days && (
                      <p>
                        <strong>Trial Days:</strong>{" "}
                        {user.subscription.trial_days}
                      </p>
                    )}
                    {user.subscription.current_period_start &&
                      user.subscription.current_period_end && (
                        <p>
                          <strong>Current Period:</strong>{" "}
                          {new Date(
                            user.subscription.current_period_start * 1000
                          ).toLocaleDateString()}{" "}
                          -{" "}
                          {new Date(
                            user.subscription.current_period_end * 1000
                          ).toLocaleDateString()}
                        </p>
                      )}

                    {/* ✅ Conditional Buttons */}
                    {user.subscription.trial_end &&
                    new Date(user.subscription.trial_end * 1000) >
                      new Date() ? (
                      <button
                        onClick={() => navigate("/pricing")}
                        className="mt-4 bg-purple-500 text-white px-4 py-2 rounded-lg hover:bg-purple-600"
                      >
                        Upgrade Plan
                      </button>
                    ) : user.subscription.status === "canceled" ? (
                      <button
                        onClick={() => navigate("/pricing")}
                        className="mt-4 bg-purple-500 text-white px-4 py-2 rounded-lg hover:bg-purple-600"
                      >
                        Choose Plan
                      </button>
                    ) : (
                      <button
                        onClick={() => handleCancelSubscription()}
                        className="mt-4 bg-red-500 text-white px-4 py-2 rounded-lg hover:bg-red-600"
                      >
                        Cancel Subscription
                      </button>
                    )}
                  </>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
