import axios from 'axios';
import React, { useEffect, useState } from 'react';
import config from "./config.json";
import { useNavigate } from "react-router-dom";


const ChatbotUI = () => {

  // 🌍 Global Speech Recognition Setup
  const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
  const recognition = SpeechRecognition ? new SpeechRecognition() : null;

  if (recognition) {
    recognition.continuous = false;
    recognition.interimResults = true;
    recognition.lang = "en-US";

    console.log(recognition);

  } else {
    console.log("Speech Recognition API is not supported in this browser.");
  }

  const [messages, setMessages] = useState([
    { text: "Hello! How can I assist you today? ", sender: "bot" },
  ]);
  const [input, setInput] = useState("");
  const [user, setUser] = useState(null);
  const [isListening, setIsListening] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [copiedIndex, setCopiedIndex] = useState(null);
  const [selectedText, setSelectedText] = useState("");
  const [selectedLanguage, setSelectedLanguage] = useState("en-US");
  const [buttonText, setButtonText] = useState([
    { name: 'Grammar Checker', plans: ['basic', 'pro', 'premium'] },
    { name: 'Passive voice checker', plans: ['basic', 'pro', 'premium'] },
    { name: 'Text format', plans: ['basic', 'pro', 'premium'] },
    { name: 'Plagiarism Checker', plans: ['pro', 'premium'] },
    { name: 'Contextual Suggestions', plans: ['pro', 'premium'] },
    { name: 'Advanced vocabulary enhancement', plans: ['pro', 'premium'] },
    { name: 'Advanced Ai Writer', plans: ['premium'] },
    { name: 'Gamification', plans: ['premium'] },
    { name: 'Voice to Text', plans: ['premium'] },
  ]);

  let languages = {
    "English": "en-US",
    "Hindi": "hi-IN",
    "German": "de-DE",
    "French": "fr-FR",
    "Spanish": "es-ES",
    "Turkish": "tr-TR",
    "Vietnamese": "vi-VN",
    "Thai": "th-TH",
    "Dutch": "nl-NL",
    "Swedish": "sv-SE",
    "Norwegian": "no-NO",
    "Finnish": "fi-FI",
    "Danish": "da-DK",
    "Czech": "cs-CZ",
    "Chinese": "zh-CN",
    "Japanese": "ja-JP",
    "Italian": "it-IT",
    "Arabic": "ar-SA",
    "Russian": "ru-RU",
    "Korean": "ko-KR",
    "Portuguese": "pt-PT"
  };

  const [selectedOptionsGroup1, setSelectedOptionsGroup1] = useState([]);
  const [selectedOptionsGroup2, setSelectedOptionsGroup2] = useState([]);

  const optionsGroup1 = [
    { label: "Clear", value: "Clear" },
    { label: "Informal", value: "Informal" },
    { label: "Academic", value: "Academic" },
    { label: "Formal", value: "Formal" },
    { label: "Creative", value: "Creative" },
  ];

  const optionsGroup2 = [
    { label: "Student", value: "Student" },
    { label: "Business", value: "Business" },
    { label: "Technical", value: "Technical" },
  ];


  const navigate = useNavigate();

  useEffect(() => {
    fetchProfile();
  }, []);

  const fetchProfile = async () => {
    try {
      const token = localStorage.getItem("token");

      if (!token) return navigate("/login");

      const res = await axios.get(`${config.apiUrl}/auth/profile`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log("Profile Response:", res.data); // Debug log

      if (res.data.isSubscribed === false && res?.data?.user_type) {

        const userCreationDate = new Date(res?.data?.createdAt.split('T')[0]); // "2025-04-01"
        const currentDate = new Date(new Date().toISOString().split('T')[0]); // Current date only

        const days = res.data.subscription.trial_days;

        // Calculate difference in days
        const daysSinceCreation = (currentDate - userCreationDate) / (1000 * 60 * 60 * 24);
        
        if (days && daysSinceCreation <= days) {
          res.data.subscription = {
            ...res.data.subscription,
            planName: res.data.user_type === 'individual' ? "Basic" : "Premium",
            status: "active"
          }
        }
      }

      setUser(res.data);

    } catch (error) {
      console.error("Error fetching profile:", error.response || error);
      navigate("/login");
    }
  };

  const isSubscribed = (plans) => {
    return (plans.includes(user?.subscription?.planName?.toLowerCase()) &&
      user?.subscription?.status === "active"
    );
  };

  const callChatGPT = async (titleText, userMessage) => {

    let prompt = "";
    if (titleText === "Grammar Checker") {
      prompt = "Please analyze the Grammar errors in the following text."
    }
    if (titleText === "Plagiarism Checker") {
      prompt = "Please analyze the following text for potential plagiarism. Indicate the percentage of plagiarism and whether the text is plagiarized based on publicly available knowledge."
    }
    if (titleText === "Contextual Suggestions") {
      prompt = "Please give Contextual Suggestions for the following text."
    }
    if (titleText === "Passive voice checker") {
      prompt = "Please check Passive voice for the following text."
    }
    if (titleText === "Advanced Ai Writer") {
      prompt = "Please Advanced Ai Writer the following text "

      if (selectedOptionsGroup1.length > 0) {
        prompt += `with ${selectedOptionsGroup1.join(", ")} style`;
      }

      if (selectedOptionsGroup2.length > 0) {
        prompt += ` for ${selectedOptionsGroup2.join(", ")}. `;
      }

    }
    if (titleText === "Text format") {
      prompt = "Please format the following text."
    }
    if (titleText === "Gamification") {
      prompt = "Please Gamification the following text."
    }
    if (titleText === "Advanced vocabulary enhancement") {
      prompt = "Please Advanced vocabulary enhancement the following text."
    }
    if (titleText === "Voice to Text") {
      prompt = "Please convert Voice to Text the following text."
    }
    prompt = prompt + `Here is the text: ${userMessage}`;

    try {
      const response = await axios.post(
        "https://api.openai.com/v1/completions",
        {
          model: "gpt-3.5-turbo-instruct",
          prompt: prompt,
          max_tokens: 150,
          temperature: 0.7
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer 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`,
          }
        }
      );

      return response.data.choices[0].text;
    } catch (error) {
      console.error("Error calling ChatGPT API:", error);
      return "Something went wrong. Please try again.";
    }
  };

  const handleSend = async (titleText) => {
    if (!input.trim()) return;
    let text = input;
    setInput("");

    setMessages([...messages, { text: text, sender: "user" }]);
    const botResponse = await callChatGPT(titleText, text);
    setMessages((prev) => [...prev, { text: botResponse, sender: "bot" }]);
    setSelectedOptionsGroup1([]);
    setSelectedOptionsGroup2([]);
  };

  const startListening = () => {

    if (!recognition) return;

    if (isListening) {
      recognition.stop(); // Stop recognition if already active
      setIsListening(false);
      handleSend('Grammar Checker');
    } else {
      setIsListening(true);
      recognition.lang = selectedLanguage;
      recognition.start();
    }

    recognition.onresult = (event) => {
      let transcript = "";
      for (let i = event.resultIndex; i < event.results.length; i++) {
        transcript += event.results[i][0].transcript;
      }
      setInput(transcript);
    };

  };

  const openModal = (index) => {

    const plans = buttonText[index].plans;

    if (isSubscribed(plans)) {
      setSelectedText(buttonText[index].name);
      setIsModalOpen(true);
    } else {
      navigate('/pricing');
    }

  };

  // Close Modal
  const closeModal = () => {
    setSelectedText('');
    setIsModalOpen(false);
    setInput("");
    if (isListening) {
      recognition.stop();
      setSelectedLanguage("en-US");
      setIsListening(false);
    }

    setMessages([
      { text: "Hello! How can I assist you today?", sender: "bot" },
    ]);
  };

  const handleCheckboxChangeGroup1 = (value) => {
    setSelectedOptionsGroup1((prev) =>
      prev.includes(value)
        ? prev.filter((item) => item !== value) // Unselect
        : [...prev, value] // Select
    );
  };

  // ✅ Toggle Selection for Group 2
  const handleCheckboxChangeGroup2 = (value) => {
    setSelectedOptionsGroup2((prev) =>
      prev.includes(value)
        ? prev.filter((item) => item !== value) // Unselect
        : [...prev, value] // Select
    );
  };

  const handleCopy = (text, index) => {
    navigator.clipboard.writeText(text);
    setCopiedIndex(index);
    setTimeout(() => setCopiedIndex(null), 1000); // Reset copied state after 1 second
  };

  return (
    <div className="max-w-4xl mx-auto px-4 py-12">
      <h1 className="text-3xl md:text-4xl font-extrabold bg-gradient-to-r from-purple-500 to-blue-500 bg-clip-text text-transparent">
        AI Grammar Assistant
      </h1>
      {
        isModalOpen && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
            <div className="bg-white p-6 rounded-lg shadow-lg w-96 relative">
              <button
                onClick={closeModal}
                className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>

              <h2 className="text-xl font-semibold mb-4">{selectedText}</h2>
              {/* Language Selector */}
              {(selectedText == 'Voice to Text') && (
                <select
                  className="w-full p-2 mb-4 border rounded-lg"
                  value={selectedLanguage}
                  onChange={(e) => setSelectedLanguage(e.target.value)}
                >
                  {Object.entries(languages).map(([name, code]) => (
                    <option key={code} value={code}>
                      {name}
                    </option>
                  ))}
                </select>
              )}

              {/* Message box */}
              <div className="mt-4 h-[300px] overflow-y-auto mb-4 space-y-2 border rounded-lg p-2">
                {messages.map((msg, index) => (
                  <div
                    key={index}
                    className={`flex ${msg.sender === "user" ? "justify-end" : "justify-start"}`}
                  >
                    <div
                      className={`relative p-2 text-sm rounded-2xl ${msg.sender === "user" ? "bg-blue-500 text-white" : "bg-gray-200 text-black"}`}
                      style={{ whiteSpace: "pre-wrap" }}
                    >
                      {msg.text}

                      {/* Copy Icon */}
                      {msg.sender !== "user" && index !== 0 && (
                        <button
                          onClick={() => handleCopy(msg.text, index)}
                          className="absolute bottom-1 right-1 text-gray-500 hover:text-gray-700"
                        >
                          {copiedIndex === index ? (
                            <span className="text-xs text-green-500">Copied!</span>
                          ) : (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="w-5 h-5"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M8 7h8m-4-4h-1a2 2 0 00-2 2v1m5-3h1a2 2 0 012 2v12a2 2 0 01-2 2H7a2 2 0 01-2-2V7a2 2 0 012-2h1m4-2v4"
                              />
                            </svg>
                          )}
                        </button>
                      )}
                    </div>
                  </div>
                ))}
              </div>
              {/* Advance ai writer */}
              {(selectedText == 'Advanced Ai Writer') && (
                <div className='mb-2'>
                  <h6 className="text-sm font-semibold mb-2">Select Writing Style</h6>
                  <div className="flex gap-3 flex-wrap">
                    {optionsGroup1.map((option) => (
                      <button
                        key={option.value}
                        onClick={() => handleCheckboxChangeGroup1(option.value)}
                        className={`px-2 py-1 text-sm border rounded-lg transition-all duration-200 ${selectedOptionsGroup1.includes(option.value)
                          ? "bg-purple-500 text-white border-purple-500"
                          : "bg-white text-gray-700 border-gray-300 hover:bg-gray-100"
                          }`}
                      >
                        {option.label}
                      </button>
                    ))}
                  </div>
                  <h3 className="text-sm font-semibold mb-2">Select Writing Style</h3>
                  <div className="flex gap-3 flex-wrap">
                    {optionsGroup2.map((option) => (
                      <button
                        key={option.value}
                        onClick={() => handleCheckboxChangeGroup2(option.value)}
                        className={`px-2 py-1 text-sm border rounded-lg transition-all duration-200 ${selectedOptionsGroup2.includes(option.value)
                          ? "bg-purple-500 text-white border-purple-500"
                          : "bg-white text-gray-700 border-gray-300 hover:bg-gray-100"
                          }`}
                      >
                        {option.label}
                      </button>
                    ))}
                  </div>
                </div>

              )}
              <div className="flex gap-2 items-center">

                <input
                  className="flex-1 border rounded-lg p-2"
                  value={input}
                  onChange={(e) => setInput(e.target.value)}
                  placeholder={(selectedText !== 'Voice to Text') ? ("Type a message...") : (isListening ? "Listening..." : "Start Speaking...")}
                />

                {(selectedText !== 'Voice to Text') && (
                  <button
                    onClick={() => handleSend(selectedText)}
                    className={`p-1  rounded-full border bg-black text-white relative shadow-md hover:scale-105 transition-transform duration-300`}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-8 h-8  rounded-full"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >

                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}

                        d="M12 16V8m0 0l-4 4m4-4l4 4"
                      />
                    </svg>
                  </button>
                )}

                {(selectedText === 'Voice to Text') && (
                  <button
                    onClick={startListening}
                    className={`p-1 rounded-full border ${isListening ? "bg-red-500 text-white" : "bg-black text-white"} relative shadow-md hover:scale-105 transition-transform duration-300`}
                  >
                    <span className="relative">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className={`w-6 h-6 ${isListening ? "animate-pulse text-white" : "text-white"}`}
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d={isListening ? "M12 1v22m8-11a8 8 0 11-16 0m12 0a4 4 0 11-8 0" : "M12 1v16m8-8a8 8 0 11-16 0m12 0a4 4 0 11-8 0"}
                        />
                      </svg>
                    </span>
                  </button>
                )}
              </div>
            </div>
          </div>

        )}

      <div className="grid md:grid-cols-3 grid-cols-1 gap-8 mt-10">
        {buttonText.map((text, index) => (
          <div
            // onClick={() => handleSend(index)}
            onClick={() => openModal(index)}
            key={index}
            className="bg-white p-6 rounded-xlrounded-xl shadow-lg hover:shadow-2xl transition duration-300 flex flex-col items-center justify-center min-h-[150px] cursor-pointer"
          >
            <h3 className="text-lg font-semibold text-purple-600 text-center">
              {text.name}
            </h3>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ChatbotUI;
