import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaEye, FaEyeSlash } from "react-icons/fa";
import axios from 'axios';
import config from './config.json';

function Login() {
  const navigate = useNavigate();

  useEffect(() => {
    const handleBackNavigation = () => {
      navigate('/'); // Redirect to home
    };

    window.history.pushState(null, null, window.location.href);
    window.addEventListener('popstate', handleBackNavigation);

    return () => {
      window.removeEventListener('popstate', handleBackNavigation);
    };
  }, [navigate]);

  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });

  const [errors, setErrors] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  const validate = (fieldValues = formData) => {
    let tempErrors = { ...errors };

    if ('email' in fieldValues) {
      tempErrors.email = !fieldValues.email
        ? 'Email is required'
        : !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(fieldValues.email)
        ? 'Invalid email address'
        : '';
    }

    if ('password' in fieldValues) {
      tempErrors.password = !fieldValues.password
        ? 'Password is required'
        : fieldValues.password.length < 6
        ? 'Password should be at least 6 characters'
        : '';
    }

    setErrors(tempErrors);
    return Object.values(tempErrors).every((x) => x === '');
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    validate({ [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validate()) {
      setLoading(true);
      try {
        const res = await axios.post(`${config.apiUrl}/auth/login`, formData);
        const { token, user } = res.data;

        localStorage.setItem('token', token);
        localStorage.setItem('userData', JSON.stringify(user));

        navigate('/'); // Redirect after successful login
      } catch (err) {
        setErrors({ email: 'Invalid credentials' });
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <div className="min-h-screen flex">
      <div className="w-1/2 flex flex-col justify-center p-20 bg-gradient-to-r from-purple-600 to-indigo-600 text-white">
        <h1 className="text-4xl font-bold mb-4">Appverse Tech</h1>
        <p className="text-lg mb-6">We’re dedicated to supporting educational institutions with cutting-edge AI applications that enhance efficiency, performance, and academic integrity.</p>
        <button
          onClick={() => navigate('/')}
          className="bg-white text-purple-600 py-2 px-6 rounded-full font-semibold hover:bg-gray-200 transition duration-300"
        >
          Explore Features
        </button>
      </div>

      <div className="w-1/2 flex items-center justify-center bg-slate-100">
        <div className="bg-white p-8 rounded-xl shadow-lg w-full max-w-md">
          <h2 className="text-2xl font-bold text-center text-purple-600 mb-6">Login to Your Account</h2>

          <form onSubmit={handleSubmit} className="space-y-4">
            <div>
              <input
                type="email"
                name="email"
                placeholder="Email"
                value={formData.email}
                onChange={handleChange}
                className={`w-full px-4 py-3 border rounded-md focus:outline-none focus:ring-2 ${
                  errors.email ? 'border-red-500 focus:ring-red-400' : 'focus:ring-purple-400'
                }`}
              />
              {errors.email && <p className="text-red-500 text-sm mt-1">{errors.email}</p>}
            </div>

            <div className="relative">
              <input
                type={showPassword ? 'text' : 'password'}
                name="password"
                placeholder="Password"
                value={formData.password}
                onChange={handleChange}
                className={`w-full px-4 py-3 border rounded-md focus:outline-none focus:ring-2 ${
                  errors.password ? 'border-red-500 focus:ring-red-400' : 'focus:ring-purple-400'
                }`}
              />
              <button
                type="button"
                className="absolute top-3 right-4"
                onClick={() => setShowPassword(!showPassword)}
              > 
                {showPassword ? <FaEyeSlash  /> : <FaEye />}
              </button>
              {errors.password && <p className="text-red-500 text-sm mt-1">{errors.password}</p>}
            </div>

            <div className="text-right">
              <button
                onClick={() => navigate('/forgot-password')}
                type="button"
                className="text-purple-500 hover:underline text-sm"
              >
                Forgot Password?
              </button>
            </div>

            <button
              type="submit"
              className={`w-full bg-purple-500 text-white font-semibold py-3 rounded-md transition duration-300 ${
                loading && 'opacity-50 cursor-not-allowed'
              }`}
              disabled={loading}
            >
              {loading ? 'Logging in...' : 'Login'}
            </button>
          </form>

          <p className="mt-4 text-center text-gray-500">
            Don't have an account?{' '}
            <button
              onClick={() => navigate('/signup')}
              className="text-purple-500 hover:underline"
            >
              Sign Up
            </button>
          </p>
        </div>
      </div>
    </div>
  );
}

export default Login;
