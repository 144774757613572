import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import config from "./config.json";

function Signup() {
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    user_type: "",
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
    terms: false,
  });

  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const isPasswordStrong = (password) => {
    const strongRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$%^&+=!]).{6,}$/;
    return strongRegex.test(password);
  };

  const validateField = (name, value) => {
    let error = "";

    if (name === "user_type") {
      if (!value) error = "User Type is required";
    }

    if (name === "firstName") {
      if (!value) error = "First name is required";
      else if (value.length < 2)
        error = "First name should be at least 2 characters";
    }

    if (name === "lastName") {
      if (!value) error = "Last name is required";
      else if (value.length < 2)
        error = "Last name should be at least 2 characters";
    }

    if (name === "email") {
      if (!value) error = "Email is required";
      else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value)) {
        error = "Invalid email address";
      }
    }

    if (name === "password") {
      if (!value) error = "Password is required";
      else if (!isPasswordStrong(value)) {
        error =
          "Password must be at least 6 characters long and include uppercase, lowercase, number, and special character";
      }
    }

    if (name === "confirmPassword") {
      if (!value) error = "Confirm password is required";
      else if (value !== formData.password) {
        error = "Passwords do not match";
      }
    }

    if (name === "terms") {
      if (!value) error = "You must accept the terms and conditions";
    }

    setErrors((prev) => ({ ...prev, [name]: error }));
    return error === "";
  };

  const validateForm = () => {
    const fields = Object.keys(formData);
    let isValid = true;

    fields.forEach((field) => {
      const isFieldValid = validateField(field, formData[field]);
      if (!isFieldValid) isValid = false;
    });

    return isValid;
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });

    validateField(name, type === "checkbox" ? checked : value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      setLoading(true);
      try {
        const response = await fetch(`${config.apiUrl}/auth/register`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        });

        const data = await response.json();

        if (response.ok) {
          localStorage.setItem("userData", JSON.stringify(formData));
          navigate("/verification");
        } else {
          alert(data.message || "Something went wrong!");
        }
      } catch (error) {
        console.error("Error:", error);
        alert("Server error, please try again.");
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <div className="flex min-h-screen">
      <div className="w-1/2 bg-purple-600 text-white flex flex-col justify-center p-12">
        <h1 className="text-4xl font-bold mb-6">Welcome to Appverse</h1>
        <p className="text-lg">
          We're dedicated to supporting educational institutions with
          cutting-edge tools that enhance efficiency, performance, and academic
          integrity.
        </p>
      </div>
      <div className="w-1/2 flex items-center justify-center bg-slate-100">
        <div className="bg-white p-8 rounded-xl shadow-lg w-full max-w-md">
          <h2 className="text-2xl font-bold text-center text-purple-600 mb-6">
            Create an Account
          </h2>
          <form onSubmit={handleSubmit} className="space-y-4">
            <select
              name="user_type"
              value={formData.user_type}
              onChange={handleChange}
              className={`w-full px-4 py-3 border rounded-md ${
                errors.user_type ? "border-red-500" : ""
              }`}
            > 
              <option value="">Select Type</option>
              <option value="individual">Individual</option>
              <option value="school_origination">School/ Origination</option>
            </select>
            {errors.user_type && (
              <p className="text-red-500">{errors.user_type}</p>
            )}

            <input
              type="text"
              name="firstName"
              placeholder="First Name"
              value={formData.firstName}
              onChange={handleChange}
              className={`w-full px-4 py-3 border rounded-md ${
                errors.firstName ? "border-red-500" : ""
              }`}
            />
            {errors.firstName && (
              <p className="text-red-500">{errors.firstName}</p>
            )}

            <input
              type="text"
              name="lastName"
              placeholder="Last Name"
              value={formData.lastName}
              onChange={handleChange}
              className={`w-full px-4 py-3 border rounded-md ${
                errors.lastName ? "border-red-500" : ""
              }`}
            />
            {errors.lastName && (
              <p className="text-red-500">{errors.lastName}</p>
            )}

            <input
              type="email"
              name="email"
              placeholder="Email"
              value={formData.email}
              onChange={handleChange}
              className={`w-full px-4 py-3 border rounded-md ${
                errors.email ? "border-red-500" : ""
              }`}
            />
            {errors.email && <p className="text-red-500">{errors.email}</p>}

            <div className="relative">
              <input
                type={showPassword ? "text" : "password"}
                name="password"
                placeholder="Password"
                value={formData.password}
                onChange={handleChange}
                className={`w-full px-4 py-3 border rounded-md ${
                  errors.password ? "border-red-500" : ""
                }`}
              />
              <span
                className="absolute right-4 top-4 cursor-pointer"
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? <FaEyeSlash /> : <FaEye />}
              </span>
            </div>
            {errors.password && (
              <p className="text-red-500">{errors.password}</p>
            )}

            <div className="relative">
              <input
                type={showConfirmPassword ? "text" : "password"}
                name="confirmPassword"
                placeholder="Confirm Password"
                value={formData.confirmPassword}
                onChange={handleChange}
                className={`w-full px-4 py-3 border rounded-md ${
                  errors.confirmPassword ? "border-red-500" : ""
                }`}
              />
              <span
                className="absolute right-4 top-4 cursor-pointer"
                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
              >
                {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
              </span>
            </div>

            {errors.confirmPassword && (
              <p className="text-red-500">{errors.confirmPassword}</p>
            )}

            <input
              type="checkbox"
              name="terms"
              checked={formData.terms}
              onChange={handleChange}
              className="mr-2"
            />
            <span>I accept the Terms and Conditions</span>
            {errors.terms && <p className="text-red-500">{errors.terms}</p>}

            <button
              type="submit"
              className="w-full bg-purple-500 text-white py-3 rounded-md"
              disabled={loading}
            >
              {loading ? "Signing Up..." : "Sign Up"}
            </button>
            <p className="text-center mt-4">
              Already have an account?{" "}
              <span
                className="text-purple-500 cursor-pointer"
                onClick={() => navigate("/login")}
              >
                Login
              </span>
            </p>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Signup;
