import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { useEffect, useState } from 'react';
import axios from 'axios';
import config from './config';
import Home from "./Home";
import Tools from "./Tools";
import Blog from "./Blog";
import Contact from "./Contact";
import Tutorials from "./Tutorials";
import Subscription from "./subscription";
import Login from "./Login";
import Signup from "./Register";
import Profile from "./profile";
import ForgotPassword from "./forgot-password";
import VerifyEmail from "./VerifyEmail";
import ChangePassword from "./change-password";
import Layout from './layout';
import DetailPage from './details';
import ChatBot from './chatbot.js';

function App() {
  const [articles, setArticles] = useState([]);

  useEffect(() => {
    const fetchArticles = async () => {
      try {
        const response = await axios.get(`${config.apiUrl}/newsAndBlogs`);
        const articles = response.data.newsAndBlogs
        .map((article) => ({
          ...article,
          featureImage: article.featureImage
            .replace("public\\", "") // ✅ Clean "public" path
            .replace(/\/+/, "/"), // ✅ Fix double slash issue
        }))
        .sort((a, b) => new Date(b.date) - new Date(a.date));
      
        setArticles(articles); // ✅ Store all articles in one state
      } catch (error) {
        console.error("Error fetching news and blogs:", error);
      }
    };

    fetchArticles();
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        {/* ✅ Routes with Navbar and Footer */}
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="/tools" element={<Tools />} />
          <Route path="/pricing" element={<Subscription />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/tutorials" element={<Tutorials tutorials={articles.filter(a => a.category === 'Tutorial')} />} />
          <Route path="/details/:id" element={<DetailPage articles={articles} />} /> {/* ✅ Unified DetailPage */}
          <Route path="/chatBot" element={<ChatBot />} />
        </Route>

        {/* ✅ Auth Routes Without Navbar & Footer */}
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/change-password/:token" element={<ChangePassword />} />
        <Route path="/verification" element={<VerifyEmail />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
