import { useParams, useNavigate } from "react-router-dom";
import FAQ from "./components/faq";
import config from "./config";
// import AiGrammarAssistant from "./videos/AiGrammarAssistant.mp4";
import video1 from "./videos/video1.mp4";
import video2 from "./videos/video2.mp4";

function DetailPage({ articles }) {
  const { id } = useParams();
  const navigate = useNavigate();
  const article = articles.find((item) => item._id === id);

  return (
    <div className="max-w-4xl mx-auto px-4 py-12">
      <h1 className="text-3xl md:text-4xl font-extrabold bg-gradient-to-r from-purple-500 to-blue-500 bg-clip-text text-transparent">
        {article?.title}
      </h1>
      <p className="text-gray-500 text-sm mt-2">
        Published on{" "}
        <span className="font-medium text-gray-700">
          {new Date(article?.date).toLocaleDateString()}
        </span>
      </p>

      {(article?.category === "Tutorial" && article?._id === '67dfae65227a24ea0584c959') ? (
        <>
          <h2 className="text-2xl font-semibold mb-4 mt-4">Universities and Language Centers Tutorial</h2>
          <div className="mb-2">
            <video controls className="w-full rounded-lg">
              <source src={video1} type="video/mp4" />
            </video>
          </div>


          <div className="space-y-4">
            <h3 className="text-xl font-semibold">Why It’s Beneficial:</h3>
            <p>Universities and language centers often have diverse user groups, such as students, educators, and
              administrators. Tailoring the tutorial for this audience helps show how the tool can support learning, academic
              writing, and language development.</p>

            <h3 className="text-lg font-semibold">Highlights</h3>
            <ul className="list-disc list-inside space-y-2">
              <li>Introduction to Academic Features: Supports academic writing, including APA/MLA/Chicago formatting, essay
                structure, and thesis papers.</li>
              <li>How to Use the Grammar Check for Student Papers: Focus on academic tone and structure.</li>
              <li>Plagiarism Checker for Original Work: Ensure originality in research papers, essays, and thesis submissions.
              </li>
              <li>Contextual Suggestions for Improved Writing: Enhance clarity, coherence, and academic quality.</li>
              <li>Using Passive Voice Detection: Suggests active voice alternatives for more effective writing.</li>
              <li>Voice-to-Text for Language Learning: Dictate essays or practice speaking.</li>
              <li>Enhanced Vocabulary for Academic Success: Build academic or discipline-specific vocabulary.</li>
              <li>Foreign Language Support: Improve writing in foreign languages, especially for ESL learners.</li>
            </ul>
          </div>

          <h2 className="text-2xl font-semibold mb-4 mt-4">Business Tutorial</h2>

          <div className="mb-2">
          <video controls className="w-full rounded-lg">
              <source src={video2} type="video/mp4" />
            </video>
          </div>

          <div className="space-y-4">
            <h3 className="text-xl font-semibold">Why It’s Beneficial:</h3>
            <p>Businesses focus on clear, professional communication. A dedicated tutorial ensures employees understand how
              to use the app for business communication, improving emails, reports, presentations, and more.</p>

            <h3 className="text-lg font-semibold">Highlights</h3>
            <ul className="list-disc list-inside space-y-2">
              <li>Introduction to Business-Specific Features: Supports emails, reports, presentations, and professional
                documents.</li>
              <li>How to Use Grammar Check for Professional Documents: Improve clarity, tone, and accuracy.</li>
              <li>Contextual Suggestions for Business Tone: Adjust tone for client emails, internal communications, and
                more.</li>
              <li>Passive Voice Detection in Business Writing: Suggests direct and impactful active voice alternatives.
              </li>
              <li>Voice-to-Text for Efficient Workflows: Quickly transcribe meetings, calls, or brainstorm sessions.</li>
              <li>Enhanced Vocabulary for Industry-Specific Communication: Master industry-specific jargon and phrases.
              </li>
              <li>Foreign Languages for International Communication: Ensure accurate and culturally appropriate
                communication.</li>
            </ul>
          </div>
        </>
      ) : (
        <>
          <div className="mt-6">
            {article?.isVideo === "0" &&
              <img
                src={`${config.apiUrl}/${article?.featureImage}`}
                alt={article?.title}
                className="w-full h-[350px] object-cover rounded-xl shadow-md"
              />
            }
            {/* {article?.isVideo == "1" &&
          <video width="100%" className="w-full  object-cover rounded-xl shadow-md" controls>
            <source src={AiGrammarAssistant} type="video/mp4" />
          </video>
        } */}
          </div>


          <div className="mt-8 bg-white p-6 md:p-8 rounded-xl shadow-lg">
            <div
              className="text-gray-700 leading-relaxed text-[17px] md:text-lg"
              dangerouslySetInnerHTML={{ __html: article?.content }}
            />
          </div>
        </>
      )
      }


      {/* ✅ Render FAQ Component only for Tutorials */}
      {article?.category === "Tutorial" && <FAQ />}

      {/* ✅ Conditional Button Logic */}
      <div className="mt-10 text-center">
        {article?.category === "Tutorial" ? (
          <button
            onClick={() => navigate("/pricing")}
            className="px-6 py-3  bg-gradient-to-r from-blue-500 to-purple-500 text-white rounded-lg shadow-md hover:from-teal-500 hover:to-green-500 transition duration-300"
          >
            Start Planning
          </button>
        ) : (
          <button
            onClick={() => navigate("/blog")}
            className="px-6 py-3 bg-gradient-to-r from-blue-500 to-purple-500 text-white rounded-lg shadow-md hover:from-purple-500 hover:to-blue-500 transition duration-300"
          >
            ← Back to Blogs
          </button>
        )}
      </div>
    </div >
  );
}

export default DetailPage;
