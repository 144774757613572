import { useState } from "react";

const FAQ = () => {
    const faqs = [
        {
          title: "Introduction to Your AI Grammar Tool & Q&A",
          openByDefault: true,
          targetAudience: "All users (teachers, students, language centers)",
          content: [
            "Overview of what the tool offers (grammar check, plagiarism check, writing styles, etc.).",
            "How subscribing to the platform unlocks all these features.",
            "Benefits of using AI for grammar, writing improvement, and plagiarism detection in an academic setting.",
            "How to sign up, navigate, and get started with the platform."
          ]
        },
        {
          title: "Getting Started with the Subscription: Unlocking Features",
          targetAudience: "New Subscribers (Teachers, Students, Institutions)",
          content: [
            "How to purchase and activate the subscription.",
            "Overview of the dashboard and where to find all your available features.",
            "How to manage your account settings and subscription preferences."
          ]
        },
        {
          title: "Grammar Check: Accuracy and AI Suggestions",
          targetAudience: "Students, Teachers",
          content: [
            "How to use the grammar check tool for essays, reports, and assignments.",
            "Understanding the AI-generated suggestions and how to accept or modify them.",
            "Common grammar mistakes the tool detects and how to improve writing."
          ]
        },
        {
          title: "Plagiarism Check: Ensuring Originality",
          targetAudience: "Students, Teachers, Language Centers",
          content: [
            "How to run plagiarism checks on written content.",
            "What the plagiarism report shows and how to interpret it.",
            "How to improve academic integrity and avoid unintentional plagiarism.",
            "Teaching students how to use plagiarism checks as a learning tool."
          ]
        },
        {
          title: "Contextual Suggestions: Enhancing Writing with AI",
          targetAudience: "Students, Teachers",
          content: [
            "How contextual suggestions work to enhance writing flow, tone, and clarity.",
            "Understanding the AI’s recommendations for sentence structure, word choice, and style.",
            "Using contextual suggestions for improving writing in different academic or professional contexts."
          ]
        },
        {
          title: "Passive Voice Detection: Improving Sentence Structure",
          targetAudience: "Students, Teachers",
          content: [
            "What is passive voice, and why should students avoid it in academic writing?",
            "How to identify passive voice and convert it to active voice using the tool.",
            "The impact of passive voice detection on clarity and readability."
          ]
        },
        {
          title: "Advanced AI Writer: Writing Styles and Modes",
          targetAudience: "Students, Teachers, Content Creators",
          content: [
            "Introduction to the AI writer and the different writing modes available.",
            "How to select specific writing styles (e.g., academic, creative, business) and tone.",
            "Using the AI writer to generate content for essays, reports, creative writing, and more.",
            "Step-by-step guide on improving writing using the AI’s suggestions and modes."
          ]
        },
        {
          title: "Vocabulary Enhancement: Expanding Language Skills",
          targetAudience: "Students, Teachers, Language Learners",
          content: [
            "How the tool suggests vocabulary enhancements to make writing more sophisticated.",
            "Understanding word choice improvements and when to use them.",
            "How to integrate vocabulary suggestions naturally into writing."
          ]
        },
        {
          title: "Text Formatting: Optimizing Readability",
          targetAudience: "Students, Teachers, Content Creators",
          content: [
            "How to use the text formatting feature for proper academic writing standards (e.g., MLA, APA).",
            "Tips for formatting essays, research papers, and assignments for submission.",
            "How to apply headings, paragraphs, and bullet points for a professional appearance."
          ]
        },
        {
          title: "Voice-to-Text: Transcribing Spoken Words into Written Text",
          targetAudience: "All users (students, teachers, professionals)",
          content: [
            "How to use the voice-to-text feature to convert spoken language into written text.",
            "Best practices for accurate transcription.",
            "Editing tips for correcting voice-to-text errors.",
            "How voice-to-text can improve productivity and assist in brainstorming or writing drafts."
          ]
        },
        {
          title: "20 Modes of Foreign Language: Grammar & Writing in Multiple Languages",
          targetAudience: "Language Learners, Teachers, Students in Multilingual Environments",
          content: [
            "Introduction to the 20 different language modes available.",
            "How to switch between languages and check grammar in each.",
            "Unique features of the AI in handling foreign languages (e.g., syntax, vocabulary, conjugation).",
            "How language learners can use the platform to improve grammar and writing in multiple languages.",
            "How to leverage the tool for bilingual education or multilingual classrooms."
          ]
        },
        {
          title: "Integrating the Tool in Classroom or Academic Settings",
          targetAudience: "Teachers, Schools, Universities, Language Centers",
          content: [
            "How to set up the platform for an entire class or institution.",
            "Using the tool to track student progress and provide real-time feedback.",
            "Customizing assignments and reports for students.",
            "Creating collaborative writing assignments with AI support.",
            "Utilizing the tool in different teaching methods, such as flipped classrooms or remote learning."
          ]
        },
        {
          title: "Advanced Features and Tips: Maximize Your Subscription",
          targetAudience: "Experienced Users (Teachers, Language Center Admins, Advanced Students)",
          content: [
            "Using AI for advanced writing tasks: research papers, thesis writing, etc.",
            "How to integrate the tool with other platforms (e.g., Google Docs, Microsoft Word).",
            "Advanced tips for customizing the writing modes for various academic fields.",
            "How to track usage and improvement over time using analytics tools."
          ]
        },
        {
          title: "Troubleshooting and Support: Getting Help",
          targetAudience: "All users",
          content: [
            "Common issues and their solutions (e.g., login, subscription management, language selection).",
            "How to contact customer support for advanced troubleshooting.",
            "FAQs about the features and subscription options."
          ]
        }
      ];
      

  const [openIndex, setOpenIndex] = useState(
    faqs.findIndex((faq) => faq.openByDefault) // Open first default FAQ
  );

  const toggleFAQ = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <div className="mt-10 bg-gray-100 p-6 rounded-xl shadow-lg">
      <h2 className="text-2xl font-bold text-gray-800 mb-4">
        Frequently Asked Questions
      </h2>
      <div className="space-y-4">
        {faqs.map((faq, index) => (
          <div key={index} className="border-b pb-2">
            <button
              className="w-full text-left font-medium text-lg flex justify-between items-center"
              onClick={() => toggleFAQ(index)}
            >
              {faq.title}
              <span className="text-blue-500">
                {openIndex === index ? "−" : "+"}
              </span>
            </button>
            {openIndex === index && (
              <div className="mt-2 text-gray-600">
                <p className="text-sm text-gray-500 italic">
                  Target Audience: {faq.targetAudience}
                </p>
                <ul className="mt-2 list-disc pl-5">
                  {faq.content.map((item, i) => (
                    <li key={i}>{item}</li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default FAQ;
