import { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import config from "./config.json";


function ChangePassword() {
  const { token } = useParams();
  const navigate = useNavigate();

  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [passwordStrength, setPasswordStrength] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (newPassword !== confirmPassword) {
      return setMessage('Passwords do not match');
    }

    if (!isValidPassword(newPassword)) {
      return setMessage('Password must be 8 characters with uppercase, number & special character');
    }

    try {
      const res = await axios.post(`${config.apiUrl}/auth/change-password/${token}`, {
        newPassword,
      });

      setMessage(res.data.message);
      setNewPassword('');
      setConfirmPassword('');

      // ✅ Redirect to login
      setTimeout(() => {
        navigate('/login');
      }, 1500);
    } catch (err) {
      setMessage('Invalid or expired token');
    }
  };

  const isValidPassword = (password) => {
    const strongPasswordRegex = /^(?=.*[A-Z])(?=.*[0-9])(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    return strongPasswordRegex.test(password);
  };

  const handlePasswordChange = (e) => {
    const password = e.target.value;
    setNewPassword(password);

    // ✅ Jese hi valid password hoga, error message remove ho jayega
    if (message) {
      setMessage('');
    }

    if (password.length < 6) {
      setPasswordStrength('Weak');
    } else if (password.length >= 6 && password.length < 8) {
      setPasswordStrength('Medium');
    } else if (isValidPassword(password)) {
      setPasswordStrength('Strong');
    } else {
      setPasswordStrength('Weak');
    }
  };

  const handleConfirmPasswordChange = (e) => {
    const confirmPass = e.target.value;
    setConfirmPassword(confirmPass);

    // ✅ Agar password match ho raha hai, error hata do
    if (newPassword === confirmPass && message === 'Passwords do not match') {
      setMessage('');
    }
  };

  return (
    <div className="flex justify-center items-center h-screen">
      <form onSubmit={handleSubmit} className="bg-white p-8 rounded-lg shadow-md w-96">
        <h2 className="text-xl font-semibold mb-4">Reset Your Password</h2>

        <div className="relative mb-4">
          <input
            type={showPassword ? "text" : "password"}
            placeholder="New Password"
            value={newPassword}
            onChange={handlePasswordChange}
            className="border p-2 w-full rounded"
          />
          <span
            className="absolute right-3 top-3 cursor-pointer text-gray-500"
            onClick={() => setShowPassword(!showPassword)}
          >
            {showPassword ? <FaEyeSlash /> : <FaEye />}
          </span>
        </div>

        {newPassword && (
          <p className={`text-sm mb-3 ${
            passwordStrength === 'Weak' ? 'text-red-500' :
            passwordStrength === 'Medium' ? 'text-yellow-500' :
            'text-green-500'
          }`}>
            Password Strength: {passwordStrength}
          </p>
        )}

        <input
          type="password"
          placeholder="Confirm Password"
          value={confirmPassword}
          onChange={handleConfirmPasswordChange}
          className="border p-2 w-full mb-3 rounded"
        />

        <button type="submit" className="bg-purple-500 text-white px-4 py-2 w-full rounded">
          Change Password
        </button>

        {message && <p className="text-red-500 mt-4">{message}</p>}
      </form>
    </div>
  );
}

export default ChangePassword;
