import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import config from "./config.json";

const plans = [
  {
    name: "Basic Plan",
    label: "Basic",
    trial: "7 Day Free Trial",
    monthlyPrice: 10,
    yearlyPrice: 100,
    // features: [
    //   "Access to basic tools",
    //   "Limited usage",
    //   "Community support",
    //   "Access to basic tools",
    //   "Limited usage",
    //   "Community support",
    // ],
    features: [
      "Grammar Check",
      "Passive Voice",
      "Text Formatting",
    ],
  },
  {
    name: "Pro Plan",
    label: "Pro",
    trial: "14 Day Free Trial",
    monthlyPrice: 20,
    yearlyPrice: 200,
    // features: [
    //   "Full access to tools",
    //   "Priority support",
    //   "Advanced analytics",
    //   "Access to basic tools",
    //   "Limited usage",
    //   "Community support",
    // ],
    features: [
      "All Basic Features",
      "Plagiarism Check",
      "Contextual Suggestions",
      "Vocabulary Enhancement",
    ],
  },
  {
    name: "Premium Plan",
    label: "Premium",
    trial: "14 Day Free Trial",
    monthlyPrice: 30,
    yearlyPrice: 300,
    // features: [
    //   "All Pro features",
    //   "24/7 support",
    //   "Premium insights",
    //   "Access to basic tools",
    //   "Limited usage",
    //   "Community support",
    // ],
    features: [
      "All Pro Features",
      "Advanced AI Writer",
      "Writing Style (Formal, Informal, Academic)",
      "Writing Modes (Student, Business, Creative, Technical)",
      "Foreign Language",
      "Voice to Text",
    ],
  },
];

function Subscription() {
  const [productData, setProductData] = useState([]);
  const [loading, setLoading] = useState(null);
  const [user, setUser] = useState(null);
  const [planDuration, setPlanDuration] = useState("month");
  const navigate = useNavigate();

  useEffect(() => {
    fetchPlans();
    fetchProfile();
  }, []);

  // 🔥 Get all plans from backend
  const fetchPlans = async () => {
    try {
      const res = await fetch(`${config.apiUrl}/payment/get-all-products`);
      const data = await res.json();
      setProductData(data);
    } catch (error) {
      console.error("Error fetching plans:", error);
    }
  };

  // 🔥 Get user profile
  const fetchProfile = async () => {
    try {
      const token = localStorage.getItem("token");
      if (!token) return;

      const res = await axios.get(`${config.apiUrl}/auth/profile`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setUser(res.data);
    } catch (error) {
      console.error("Error fetching profile:", error.response || error);
    }
  };

  // 🔥 Match plan and interval to get priceId
  const getPriceId = (planName, interval) => {
    const matchedPlan = productData.find(
      (p) =>
        p.name.toLowerCase() === planName.toLowerCase() &&
        p.interval === interval
    );
    return matchedPlan?.priceId;
  };

  // 🔥 Check subscription status
  const isSubscribed = (planName) => {
    return (
      user?.subscription?.planName?.toLowerCase() === planName.toLowerCase() &&
      user?.subscription?.status === "active"
    );
  };

  // 🔥 Handle subscription
  const handleSubscription = async (planName, interval) => {
    if (isSubscribed(planName)) return;

    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/login");
      return;
    }

    const priceId = getPriceId(planName, interval);
    if (!priceId) {
      console.error("Price ID not found");
      return;
    }

    setLoading(planName);

    try {
      const res = await fetch(`${config.apiUrl}/payment/create-checkout-session`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ priceId }),
      });

      const data = await res.json();
      setLoading(null);

      if (data.sessionUrl) {
        window.location.href = data.sessionUrl;
      } else {
        console.error("Stripe session creation failed:", data);
      }
    } catch (error) {
      console.error("Error creating checkout session:", error);
      setLoading(null);
    }
  };

  return (
    <div className="bg-slate-100 text-black min-h-screen">
      <div className="max-w-[920px] m-auto px-4 md:px-0 p-20">
        {/* ✅ Heading */}
        <h1 className="text-4xl font-bold gradient-text text-center">
          Subscription Plans
        </h1>
        <p className="mt-5 text-center text-sm">
          Choose the plan that suits your needs and start enhancing your journey.
        </p>

        {/* ✅ Tabs for Plan Duration */}
        <div className="flex justify-center mt-8 gap-4">
          {["month", "year"].map((duration) => (
            <button
              key={duration}
              onClick={() => setPlanDuration(duration)}
              className={`py-2 px-6 rounded-lg font-semibold ${planDuration === duration
                  ? "bg-purple-600 text-white"
                  : "bg-gray-200 text-gray-600"
                }`}
            >
              {duration.charAt(0).toUpperCase() + duration.slice(1)}ly
            </button>
          ))}
        </div>

        {/* ✅ Plans Section */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 mt-10">
          {plans.map((plan) => (
            <div
              key={plan.label}
              className="bg-white p-6 rounded-xl shadow-md hover:shadow-lg transition duration-300 flex flex-col h-full"
            >
              {/* ✅ Plan Label */}
              <h3 className="text-xl lg:text-2xl font-semibold text-purple-600 text-center">
                {plan.label}
              </h3>

              {/* ✅ Trial */}
              <p className="text-gray-500 text-center text-sm mt-1">
                {plan.trial}
              </p>

              {/* ✅ Pricing */}
              <div className="mt-2 text-center">
                <p className="text-lg lg:text-xl font-bold">
                  ${planDuration === "month" ? plan.monthlyPrice : plan.yearlyPrice}
                  {planDuration === "month" ? "/Month" : "/Year"}
                </p>
              </div>

              {/* ✅ Features */}
              <ul className="mt-6 mb-3 space-y-2 text-gray-600 text-sm flex-1 overflow-y-auto">
                {plan.features.map((feature, index) => (
                  <li key={index} className="flex items-center">
                    <span className="mr-2 text-green-500">✔</span>
                    {feature}
                  </li>
                ))}
              </ul>

              {/* ✅ Subscribe Button */}
              <div className="mt-auto">
                {isSubscribed(plan.label) ? (
                  <button
                    disabled
                    className="w-full bg-gray-300 text-gray-500 py-2 rounded-md font-semibold cursor-not-allowed"
                  >
                    Subscribed
                  </button>
                ) : (
                  <button
                    onClick={() => handleSubscription(plan.label, planDuration)}
                    className="w-full bg-purple-500 text-white py-2 rounded-md font-semibold hover:bg-purple-600 transition duration-300"
                  >
                    {loading === plan.label ? "Processing..." : "Subscribe"}
                  </button>
                )}
              </div>
            </div>
          ))}
        </div>

      </div>
    </div>
  );
}

export default Subscription;
