import React, { useEffect, useState } from 'react';
import axios from 'axios';
import config from './config.json';

const Blog = () => {
  const [news, setNews] = useState([]);
  const [blogs, setBlogs] = useState([]);

  useEffect(() => {
    axios
      .get(`${config.apiUrl}/newsAndBlogs`)
      .then(response => {
        const articles = response.data.newsAndBlogs.map(article => ({
          ...article,
          featureImage: article.featureImage.replace('public\\', ''),
        }));

        const newsArticles = articles.filter(article => article.category === 'News');
        const blogArticles = articles.filter(article => article.category === 'Blog');

        setNews(newsArticles);
        setBlogs(blogArticles);
      })
      .catch(error => console.error('Error fetching news and blogs:', error));
  }, []);

  return (
    <div className="bg-slate-100 text-black">
      {/* ✅ Hero Section */}
      <div className="bg-gradient-to-tl to-[#a855f730] from-[#3b82f630] -mt-12">
        <div className="max-w-[920px] m-auto px-4 md:px-0 py-28">
          <h1 className="text-5xl font-bold gradient-text text-center">News & Blogs</h1>
          <p className="mt-5 w-[80%] m-auto text-center text-gray-600">
            We’re dedicated to supporting educational institutions with cutting-edge AI applications that enhance efficiency, performance, and academic integrity.
          </p>
        </div>
      </div>

      <main className="max-w-[1100px] m-auto px-4 md:px-0">
        {/* ✅ Latest News Section */}
        <div className="bg-white p-6 rounded-xl shadow-lg">
          <h2 className="text-3xl font-bold text-purple-500 text-center">Latest News</h2>
          <p className="text-sm mt-2 w-[80%] m-auto text-center text-gray-600">
            Stay updated with the latest news and insights from the industry.
          </p>

          {/* ✅ News Grid */}
          <div className="grid md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-6 mt-8">
            {news.map(article => (
              <div
                key={article._id}
                className="bg-slate-50 rounded-xl shadow-md overflow-hidden transition-transform transform hover:-translate-y-1 hover:shadow-2xl"
              >
                {/* ✅ Image Section */}
                <img
                  src={`${config.apiUrl}/${article.featureImage}`}
                  alt={article.title}
                  className="w-full h-48 object-cover"
                />

                {/* ✅ Content Section */}
                <div className="p-5">
                  <p className="text-xs text-gray-400">
                    {new Date(article.date).toLocaleDateString()}
                  </p>
                  <h3 className="font-semibold text-lg text-gray-800 mt-2">
                    {article.title}
                  </h3>
                  <p className="text-sm text-gray-600 mt-1 line-clamp-2">
                    {article.overview}
                  </p>

                  {/* ✅ Gradient Button */}
                  <a
                    href={`/details/${article._id}`}
                    className="mt-4 inline-block px-4 py-2 rounded-lg bg-gradient-to-r from-purple-500 to-blue-500 text-white font-medium hover:from-blue-500 hover:to-purple-500 transition duration-300"
                  >
                    Read More →
                  </a>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* ✅ Blogs Section */}
        <div className="bg-white p-6 rounded-xl shadow-lg mt-12">
          <h2 className="text-3xl font-bold text-purple-500 text-center">Blogs</h2>
          <p className="text-sm mt-2 w-[80%] m-auto text-center text-gray-600">
            Explore the latest blog posts from industry experts.
          </p>

          {/* ✅ Blogs Grid */}
          <div className="grid md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-6 mt-8">
            {blogs.map(article => (
              <div
                key={article._id}
                className="bg-slate-50 rounded-xl shadow-md overflow-hidden transition-transform transform hover:-translate-y-1 hover:shadow-2xl"
              >
                {/* ✅ Image Section */}
                <img
                  src={`${config.apiUrl}/${article.featureImage}`}
                  alt={article.title}
                  className="w-full h-48 object-cover"
                />

                {/* ✅ Content Section */}
                <div className="p-5">
                  <p className="text-xs text-gray-400">
                    {new Date(article.date).toLocaleDateString()}
                  </p>
                  <h3 className="font-semibold text-lg text-gray-800 mt-2">
                    {article.title}
                  </h3>
                  <p className="text-sm text-gray-600 mt-1 line-clamp-2">
                    {article.overview}
                  </p>

                  {/* ✅ Gradient Button */}
                  <a
                    href={`/details/${article._id}`}
                    className="mt-4 inline-block px-4 py-2 rounded-lg bg-gradient-to-r from-purple-500 to-blue-500 text-white font-medium hover:from-blue-500 hover:to-purple-500 transition duration-300"
                  >
                    Read More →
                  </a>
                </div>
              </div>
            ))}
          </div>
        </div>
      </main>
    </div>
  );
};

export default Blog;
