import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import config from './config.json';

function ForgotPassword() {
  const navigate = useNavigate();

  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [apiHit, setApiHit] = useState(false);

  const validateEmail = (email) => {
    if (!email) return 'Email is required';
    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)) {
      return 'Invalid email address';
    }
    return '';
  };

  const handleChange = (e) => {
    setEmail(e.target.value);
    setError('');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const validationError = validateEmail(email);
    if (validationError) {
      setError(validationError);
      return;
    }

    try {
      setLoading(true);
      await axios.post(`${config.apiUrl}/auth/forgot-password`, { email });
      setSuccessMessage('Password reset link sent to your email.');
      setApiHit(true);
      setEmail('');
    } catch (err) {
      console.error(err);
      setError('Email not registered');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex min-h-screen w-full">
      {/* Left Side */}
      <div className="w-1/2 bg-purple-600 text-white flex flex-col justify-center px-10">
        <h1 className="text-4xl font-bold mb-4">Appverse Tech</h1>
        <p className="text-lg">We're dedicated to supporting educational institutions with cutting-edge AI applications that enhance efficiency, performance, and academic integrity.</p>
      </div>

      {/* Right Side */}
      <div className="w-1/2 flex items-center justify-center bg-slate-100">
        <div className="bg-white p-8 rounded-xl shadow-lg w-full max-w-md">
          <h2 className="text-2xl font-bold text-center text-purple-600 mb-6">Forgot Password</h2>

          {successMessage && (
            <p className="text-green-500 text-center mb-4">{successMessage}</p>
          )}

          {!apiHit && (
            <form onSubmit={handleSubmit} className="space-y-4">
              <div>
                <input
                  type="email"
                  placeholder="Enter your email"
                  value={email}
                  onChange={handleChange}
                  className={`w-full px-4 py-3 border rounded-md focus:outline-none focus:ring-2 ${error ? 'border-red-500 focus:ring-red-400' : 'focus:ring-purple-400'}`}
                />
                {error && <p className="text-red-500 text-sm mt-1">{error}</p>}
              </div>

              <button
                type="submit"
                disabled={loading}
                className={`w-full bg-purple-500 text-white font-semibold py-3 rounded-md transition duration-300 ${loading && 'opacity-50 cursor-not-allowed'}`}
              >
                {loading ? 'Sending...' : 'Send Reset Link'}
              </button>
            </form>
          )}

          <div className="text-center mt-4">
            <p>
              Remember your password?{' '}
              <button
                onClick={() => navigate('/login')}
                className="text-purple-500 hover:underline"
              >
                Back to Login
              </button>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ForgotPassword;
