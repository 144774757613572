import { Outlet } from 'react-router-dom';
import Navbar from './components/Navbar';


const Layout = () => {
  return (
    <div>
      <Navbar />
      <Outlet />
      <footer className="bg-white text-center p-3">
        <p className="text-slate-400 text-xs">© 2025 Appverse Tech LLC. All rights reserved.</p>
      </footer>
    </div>
  );
};

export default Layout