import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import config from './config.json';

function VerifyEmail() {
  const navigate = useNavigate();
  const email = JSON.parse(localStorage.getItem("userData"))?.email || "";

  const [otp, setOtp] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [timer, setTimer] = useState(60);
  const [resendEnabled, setResendEnabled] = useState(false);

  const handleChange = (e) => {
    setOtp(e.target.value);
    setError("");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!otp) {
      setError("OTP is required");
      return;
    }

    setLoading(true);

    try {
      const response = await fetch(`${config.apiUrl}/auth/verify-email`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, otp }),
      });

      const data = await response.json();

      if (response.ok) {
        localStorage.setItem("token", data.token);
        localStorage.setItem("userData", JSON.stringify(data.user));
        navigate("/");
      } else {
        setError(data.message || "Invalid OTP. Please try again.");
      }
    } catch (error) {
      console.error("Error:", error);
      setError("Server error, please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleResendOTP = async () => {
    setLoading(true);

    try {
      const response = await fetch(`${config.apiUrl}/auth/resend-otp`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email }),
      });

      const data = await response.json();

      if (response.ok) {
        alert("New OTP sent to your email.");
        setResendEnabled(false);
        setTimer(60);
      } else {
        setError(data.message || "Failed to resend OTP.");
      }
    } catch (error) {
      console.error("Error:", error);
      setError("Server error, please try again.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    let interval;

    if (timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTime) => prevTime - 1);
      }, 1000);
    } else {
      setResendEnabled(true);
    }

    return () => clearInterval(interval);
  }, [timer]);

  return (
    <div className="flex min-h-screen">
      <div className="w-1/2 bg-purple-600 text-white flex flex-col justify-center p-12">
        <h1 className="text-4xl font-bold mb-6">Welcome to Appverse</h1>
        <p className="text-lg">We're dedicated to supporting educational institutions with cutting-edge tools that enhance efficiency, performance, and academic integrity.</p>
      </div>
      <div className="w-1/2 flex items-center justify-center bg-slate-100">
        <div className="bg-white p-8 rounded-xl shadow-lg w-full max-w-md">
          <h2 className="text-2xl font-bold text-center text-purple-600 mb-6">Verify Your Email</h2>
          <form onSubmit={handleSubmit} className="space-y-4">
            <input
              type="text"
              placeholder="Enter OTP"
              value={otp}
              onChange={handleChange}
              className={`w-full px-4 py-3 border rounded-md ${error ? "border-red-500" : ""}`}
            />
            {error && <p className="text-red-500 text-sm">{error}</p>}

            <button
              type="submit"
              className="w-full bg-purple-500 text-white py-3 rounded-md"
              disabled={loading}
            >
              {loading ? "Verifying..." : "Verify OTP"}
            </button>

            <p className="text-center text-sm text-gray-500">
              {resendEnabled ? "Didn't receive OTP?" : `Resend OTP in ${timer} sec`}
            </p>

            {resendEnabled && (
              <button
                onClick={handleResendOTP}
                className="text-blue-500 font-semibold hover:underline"
                disabled={loading}
              >
                Resend OTP
              </button>
            )}
          </form>
        </div>
      </div>
    </div>
  );
}

export default VerifyEmail;
